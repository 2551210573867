<!--
 * @Description: 报价列表
 * @Author: zhang zhen
 * @Date: 2023-07-28 16:52:51
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-18 23:01:30
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceList.vue
-->
<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input v-model="queryParams.title" placeholder="请输入需求单号、需求名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px;" />
        <div class="option-area">
          <a-button @click="reaLoad" style="width: 81px;">重置</a-button>
          <a-button type="primary" @click="handleSearch" style="width: 81px;">查询</a-button>
        </div>
      </div>
      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="left" style="margin-top: 8px;"
        :colon="false">
        <a-form-model-item label="报价类型">
          <checkboxSearch v-model="queryParams.quoteSource" :options="quoteOptions" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="报价状态">
          <checkboxSearch v-model="queryParams.status" :options="mainOptions" :needLoad="false" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table :columns="columns" :loading="loading" :data-source="dataSource" :pagination="false"
        style="margin-bottom: 16px;" :scroll="{ x: 'max-content' }" rowKey="index">
        <template slot="quoteSource" slot-scope="text, record">
          {{ text == 1 ? '主动报价' : '邀请报价' }}
        </template>
        <template slot="quoteDeadline" slot-scope="text, record">
          <div>
            {{ text && text.substring(0, 10) || ''}}
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <!-- <a @click="handleSwitchTab(record)">详情</a> -->
          <!-- <a>重新报价</a> -->
          <a @click="handleQuote(record)" v-if="record.status == '3'">立即报价</a>
          <a @click="handleQuote(record)"
            v-if="record.status == '0' || (record.status == '-1' && record.isPass != 1)">重新报价</a>
          <template v-if="record.status == '0'">
            <a-divider type="vertical" />
            <a @click="handleCancel(record.quoteId)">取消报价</a>
          </template>
          <template v-if="record.status == '1' || record.status == '-1' || record.status == '2'">
            <a-divider type="vertical" v-if="record.status == '0' || (record.status == '-1' && record.isPass != 1)" />
            <a @click="handleSwitchTab(record)">详情</a>
          </template>
        </template>
      </a-table>
      <ZPagination :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage" />
    </div>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'

export default {
  name: 'quotePriceList',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch
  },
  data() {
    return {
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      columns: [
        {
          title: '需求编号',
          dataIndex: 'purchaseNo',
          key: 'purchaseNo',
          width: 218
        },
        {
          title: '需求名称',
          dataIndex: 'purchaseTitle',
          key: 'purchaseTitle',
          width: 200
        },
        {
          title: '报价类别',
          dataIndex: 'quoteSource',
          key: 'quoteSource',
          width: 100,
          scopedSlots: { customRender: 'quoteSource' }
        },
        {
          title: '截止报价时间',
          dataIndex: 'quoteDeadline',
          key: 'quoteDeadline',
          scopedSlots: { customRender: 'quoteDeadline' },
          width: 140,
        },

        {
          title: '报价状态',
          dataIndex: 'statusLabel',
          key: 'statusLabel',
          width: 110
        },
        {
          title: '报价时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180

        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 161,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '主动报价'
        },
        {
          dictKey: '2',
          dictValue: '邀请报价'
        },
      ],
      mainOptions: [

      ],
      requestType: 'post',
      url: {
        list: "/quote/list" //0827
      },
    }
  },
  created() {
    this.handleInit();
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleInit() {
      getAction('/quote/dictionary').then(res => {
        const { success, data } = res;
        success && (this.mainOptions = data);
      })
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      const { quoteSource, status, title } = this.queryParams;
      let quoteSourceList = [], statusList = [];
      if (quoteSource) {
        quoteSourceList = quoteSource.split(',');
      }
      if (status) {
        statusList = status.split(',');
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction;
      api(this.url.list, {
        title: title || '',
        status: statusList,
        quoteSource: quoteSourceList,
        pagesSize: pageSize,
        pageNum,
        ...this.sorter
      }).then(res => {
        const { success, data } = res;
        this.loading = false
        if (success) {
          const { total, list } = data
          this.dataSource = list
          this.pageNation['total'] = total
        }
      }).catch(e => {
        this.loading = false
      })
    },
    /* 参与报价 */
    handleQuote(item) {
      this.$router.push(`/quotePriceManagement/quotePriceDetailsView?id=${item.purchaseId}&quoteSource=${item.quoteSource}&quoteId=${item.quoteId}`)
    },
    handleSwitchTab(item) {
      const { quoteId, purchaseId, businessId } = item
      this.$router.push(`/quotePriceManagement/quotePriceInfoView?purchaseId=${purchaseId}&quoteId=${quoteId}&isDetail=1&businessId=${businessId}`)
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.queryParams = {}
      this.rangeDate = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    // 取消报价
    handleCancel(quoteId) {
      postAction('/quote/cancel', {
        quoteId
      }).then(res => {
        const { success, message } = res;
        if (success) {
          this.$message.success('报价已经取消')
          this.reaLoad()
        } else {
          this.$message.warning(message)
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

::v-deep .ant-table-column-title {
  font-size: 16px;
}

::v-deep .ant-table-thead {
  tr {
    background: #efefef;

    th {
      border: none;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    &:not(:last-of-type)>td {
      border: none;
    }

    &>td {
      border-bottom: 1px solid #EFEFEF;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead>tr>th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;

}

.searchInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    .ant-btn {
      width: 97px;
      height: 38px;

      &+.ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';
::v-deep .ant-form-item-label {
  width: 67px!important;
}
</style>
